import logo from './logo.svg';
import davidLogo from './david-drawing.png';
import carousel from './frescera-carousel.png'
import blacktextLogo from './frescera-blacktext.png';
import smallFresceraLogo from './frescera-blackbackground250.png'
import { PopupButton } from '@typeform/embed-react'
import {Container, Row, Col} from "react-bootstrap";
import './App.css';

function App() {

  return (

    <div className="App">
      <header className="App-header">
          <div className="content-div">
        <img src={davidLogo} className="David-logo" alt="logo" />
          <img src={carousel} className="David-logo" alt="logo" />
          <PopupButton id="NbGEKd2o" className="waitlist-button">
              join waitlist
          </PopupButton>
          </div>
      </header>
    </div>
  );
}

export default App;
